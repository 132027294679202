import React from "react";
import { Helmet } from "react-helmet";

let FAVICON = "";
export function setDefaults({ favicon }) {
  FAVICON = favicon;
}

export default function renderHelmet({
  title,
  description,
  keywords,
  author,
  id,
}) {
  return (
    <Helmet>
      <title>{title}</title>
      <link rel="icon" href={FAVICON} />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="author" content={author} />
      {id?.startsWith("page_not_found") && (
        <meta name="robots" content="noindex, nofollow" />
      )}
    </Helmet>
  );
}
