import { UPDATE_GOOGLE_MAP_API_KEY } from "external/store/reducers/gMap";
import { showNotification } from "helpers/utils";
import { useDispatch } from "react-redux";
import graphqlService from "external/services/graphql/graphqlService";
import { useTranslation } from "react-i18next";
import { ORDERING_TYPE_QR } from "constants/enums.constants";

const useGoogleMapsServices = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const fetchGmapKey = async (setMapsKey, setKeyPresent) => {
    try {
      const orderingMode = window.ROOT_CONFIG.biz.mode;
      const key =
        orderingMode !== ORDERING_TYPE_QR
          ? await graphqlService.getGmapKey()
          : null;
      if (key) {
        setKeyPresent && setKeyPresent(true);
        setMapsKey && setMapsKey(key);
        dispatch({ type: UPDATE_GOOGLE_MAP_API_KEY, payload: key });
      } else if (!key && orderingMode !== ORDERING_TYPE_QR) {
        showNotification(t("common.MapKeyError"), "error");
      }
    } catch (error) {
      showNotification(t("common.MapKeyError"), "error");
      console.error(error);
    }
  };

  const fetchAutoSuggestion = async (
    searchText,
    countryCode = "+91",
    currency,
    onSuccess,
    onLoading,
  ) => {
    onLoading && onLoading(true);
    const gMapSessionToken = sessionStorage.getItem("gMapSessionToken") || null;

    try {
      const predictions = await graphqlService.getPlacesSuggestions(
        searchText,
        countryCode,
        currency,
        gMapSessionToken,
      );
      if (predictions) {
        onSuccess && onSuccess(predictions);
      } else {
        showNotification(t("common.AutocompleteError"), "error");
      }
    } catch (error) {
      console.error(error);
      showNotification(t("common.AutocompleteError"), "error");
    } finally {
      onLoading && onLoading(false);
    }
  };

  const fetchGeocodeDetails = async (lat, lng) => {
    try {
      const geocodeDetails = await graphqlService.performGeocoding(lat, lng);
      return geocodeDetails;
    } catch (error) {
      console.error(error);
      showNotification(t("common.GeocodeFail"), "error");
    }
  };

  const fetchPlaceDetails = async (place_id, onSuccess) => {
    const gMapSessionToken = sessionStorage.getItem("gMapSessionToken") || null;

    try {
      const placeData = await graphqlService.getPlaceDetails(
        place_id,
        gMapSessionToken,
      );
      if (placeData) {
        const { lat, lng } = placeData;
        onSuccess && onSuccess({ lat, lng });
        return placeData;
      } else {
        showNotification(t("common.PlaceFetchFail"), "error");
      }
    } catch (error) {
      console.error(error);
      showNotification(t("common.PlaceFetchFail"), "error");
      return {};
    }
  };

  return {
    fetchGmapKey,
    fetchAutoSuggestion,
    fetchGeocodeDetails,
    fetchPlaceDetails,
  };
};

export default useGoogleMapsServices;
