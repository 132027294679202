import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  createRef,
  useCallback,
} from "react";

import Shimmer from "react-shimmer-effect";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { bright_90, increaseBrightness } from "external/helpers/adjustColor";
import configSelector from "external/store/selectors/configSelector";
import ChevronIcon from "../../assets/icons/chevron.icon";
import CrossIcon from "../../assets/icons/cross.icon";
import { BaseContext } from "../../context/BaseContext";
import createClass from "../../helpers/createCssClass";
import {
  ITEM_CATEGORY_DUMMY_DATA,
  ITEM_DUMMY_DATA,
} from "../../mock-data/index.mock-data";
import FloatingActionButton from "../floating-action-button/index.component";
import ItemCard from "../item-card/index.component";
import Typography from "../typography/index.component";
import AppliedFilter from "./AppliedFilter";
import CategoryNavigator from "./navigators/CategoryNavigators";
import MobileCategoryNavigator from "./navigators/MobileCategoryNavigators";
import Section from "./category-section/index.component";
import ResponsiveHelper from "external/helpers/responsive.helper";
import {
  COLOR_NIGHT_RIDER,
  COLOR_LIGHT_BLACK,
  COLOR_WHITE,
} from "../../constants/colors.constants";
import GenericErrorInline from "../generic-error-inline/index.component";
import GenericErrorDialog from "../generic-error-dialog/index.component";
import useRouteHook from "../../hooks/useRoute.hook";
import { updateCatalogue } from "external/store/actions/catalogue";
import analyticsPubSub, {
  ANALYTICS_EVENTS,
} from "external/services/analyticsPubSub";
import { connect, useDispatch } from "react-redux";
import { useIsMount } from "../../hooks/useMount.hook";

import "./index.component.scss";
import EmptyList from "../../assets/icons/empty-list.icon";

import Menu from "@material-ui/core/Menu";

const ItemList = ({
  config,
  categoryWiseItemList,
  loading,
  recommendedError,
  catalogueError,
  cart,
  match,
  fulfillmentType,
  updateCatalogueLoading,
  currentOrder,
  listData,
  categories,
}) => {
  const BaseConsumer = useContext(BaseContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [appliedFilters, setAppliedFilter] = useState([]);
  const [categoryScrollEvent, setCategoryScrollEvent] = useState(undefined);
  const [activeMainCategory, setActiveMainCategory] = useState(null);
  const [activeSubCategory, setActiveSubCategory] = useState(null);
  const [observerConfig, setObserverConfig] = useState(null);
  const [errorDialog, setErrorDialog] = useState(false);
  const [showingSearchResults, setshowingSearchResults] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);
  const scrollTopMarkerRef = useRef(null);
  const headerRef = useRef(null);
  const categoryLinksRef = useRef(null);
  const isMountedRef = useRef(false);
  const { historyPush } = useRouteHook();
  const dispatch = useDispatch();
  const isMounted = useIsMount();

  // variables
  const { t } = useTranslation();
  const screenHeight = window.innerHeight;
  const screenWidth = window.innerWidth;
  const primaryColor = configSelector.getPrimaryColor({ config });
  const primaryTextColor = configSelector.getPrimaryTextColor({ config });
  const secondaryColor = configSelector.getSecondaryColor({ config });
  const secondaryTextColor = configSelector.getSecondaryTextColor({ config });
  const selectedFontFamily = configSelector.getFontFamily({ config });
  const {
    sortBy,
    filterBy,
    isMobileView,
    options,
    handleRemoveFilters,
    menuRoute,
    sticky,
    setSticky,
    isQrModeEnabled,
    announcement,
  } = BaseConsumer;

  useEffect(() => {
    const header = document.getElementsByClassName("x-js-header")[0];
    if (header) {
      setHeaderHeight(header.getBoundingClientRect().height);
      const topMargin = Math.abs(header.getBoundingClientRect().height + 80);
      const bottomMargin = Math.abs(screenHeight - topMargin - 40);

      setObserverConfig({
        rootMargin: `-${topMargin}px 0px -${bottomMargin}px 0px`,
        thresholds: [0.05, 1],
      });
    }
  }, [screenHeight, screenWidth, announcement, sticky.isSticky]);

  const fetchVisibleCategory = (categoryId) => {
    if (typeof categoryId !== "number") return;

    // if category is already loaded, don't fetch again.
    if (listData?.find((category) => category.id === categoryId)?.items?.length)
      return;

    return dispatch(
      updateCatalogue(categoryId, sortBy, filterBy, fulfillmentType),
    );
  };

  // handle scroll event
  const handleScroll = (elHeight) => {
    if (ResponsiveHelper.isMobile(screenWidth)) return;
    const categoryLinkList = document.getElementById("item-list-wrapper");
    const header = document.getElementsByClassName("x-js-header")[0];
    const windowYOffset = window.pageYOffset + (header.offsetHeight - 120);
    if (windowYOffset > categoryLinkList?.offsetTop) {
      setSticky({ isSticky: true, offset: elHeight });
    } else {
      setSticky({ isSticky: false, offset: 0 });
    }
  };

  const handleAutoScroll = (activeCategoryEle) => {
    var bounding = activeCategoryEle.getBoundingClientRect();
    const categoryLinkListEle = document.getElementById("category-link-list");
    const categoryLinkListEleWidth = categoryLinkListEle.offsetWidth;
    const widthOffset = (screenWidth - categoryLinkListEleWidth) / 2;

    if (
      bounding.top >= 0 &&
      bounding.left - widthOffset >= 0 &&
      bounding.right - widthOffset <= categoryLinkListEleWidth &&
      bounding.bottom <= screenHeight
    ) {
    } else {
      if (bounding.left + widthOffset >= 0) {
        // Move left!
        scrollCategoryLink(300);
      }
      if (bounding.right - widthOffset <= categoryLinkListEleWidth) {
        // Move right!
        scrollCategoryLink(-300);
      }
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // add/remove scroll event listener
  useEffect(() => {
    var header = headerRef.current.getBoundingClientRect();
    const handleScrollEvent = () => {
      handleScroll(header.top, header.height);
      /**
       * Due to async nature of intersectionObserver, it may fail to catch
       * the intersections when a user scrolls fast, the below code make sures if the
       * everything is reset if the user is at top of the page
       */
      if (window.scrollY === 0) {
        if (activeMainCategory) {
          setActiveMainCategory(null);
        }
        if (activeSubCategory) {
          setActiveSubCategory(null);
        }
        historyPush(menuRoute);
      }
    };
    window.addEventListener("scroll", handleScrollEvent);
    if (categoryLinksRef) {
      setCategoryScrollEvent({
        scrollLeft: categoryLinksRef.current?.scrollLeft,
        scrollWidth: categoryLinksRef.current?.scrollWidth,
        clientWidth: categoryLinksRef.current?.clientWidth,
      });
    }
    //active class creation and setting
    if (ResponsiveHelper.isMobile(screenWidth)) {
      createClass(
        ".active",
        `background-color: none;
                            text-shadow: 0.5px 0 0 ${primaryColor};
                            color: ${primaryColor} !important`,
      );
    } else {
      createClass(
        ".active",
        `background-color: ${increaseBrightness(
          primaryColor,
          bright_90,
        )} !important;
                            text-shadow: 0.5px 0 0 ${primaryColor};
                            color: ${primaryColor} !important`,
      );
    }
    // creating sub-category-active-class
    createClass(
      ".sub-category-active",
      `background-color: none;
                          text-shadow: 0.2px 0 0 ${COLOR_LIGHT_BLACK};
                          color: ${COLOR_LIGHT_BLACK} !important`,
    );
    return () => {
      window.removeEventListener("scroll", handleScrollEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryColor]);

  const persistPositionOnRefresh = useCallback(() => {
    /**
     * Promotional url's can come with different params attached to the main
     * url. React router considers whatever comes after menuRoute as a category-slug
     * If the slug contains characters such as "." or any other characters that are not
     * allowed in querySelector, this will cause an error. Therefore we are handling it by
     * try catch block.
     */
    try {
      if (updateCatalogueLoading) return;
      let subPath = configSelector.getSubPath({ config });
      subPath = subPath ? `/${subPath}` : "";
      if (match.path === `${menuRoute}${subPath}/:catSlug`) {
        const categorySlug = match.params.catSlug;
        const category = categories.find((cat) => cat.slug === categorySlug);
        const subCatId = category?.sub_categories?.[0]?.id;
        const catId = category.id;

        const mainSection = document.querySelector(
          `div[data-cat-slug=s-${subCatId || catId}]`,
        );

        if (mainSection) {
          const mainSectionId = mainSection.id;
          fetchVisibleCategory(mainSectionId);
          handleScrollToActiveSection(mainSectionId);
        }
      } else if (match.path === `${menuRoute}${subPath}/:catSlug/:subCatSlug`) {
        const subCategorySlug =
          match.params.catSlug + "/" + match.params.subCatSlug;
        const catId = listData.find((cat) => cat.slug === subCategorySlug)?.id;
        const subSection = document.querySelector(
          `div[data-cat-slug=s-${catId}]`,
        );

        if (subSection) {
          const subSectionId = subSection.id;
          fetchVisibleCategory(subSectionId);
          handleScrollToActiveSection(subSectionId);
        }
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    match?.params.catSlug,
    match?.params.subCatSlug,
    match?.path,
    updateCatalogueLoading,
    categoryWiseItemList,
    listData,
    isMounted,
  ]);

  useEffect(() => {
    if (recommendedError && catalogueError) {
      setErrorDialog(true);
    }
  }, [recommendedError, catalogueError]);

  const handleErrorDialogClose = () => {
    setErrorDialog(false);
  };

  useEffect(() => {
    if (!loading && isMounted) {
      // Added setTimeout here because `bodyRectTop` was returning 0.
      // This caused `handleScrollToActiveSection` inside `persistPositionOnRefresh`
      // to not work as expected when redirecting from the landing screen.

      setTimeout(() => {
        persistPositionOnRefresh();
      }, 500);
    }

    /**
     * misbehaves if we have have persistPostionOnRefresh in the
     * dependecy array
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, isMounted]);

  const populateAppliedFilters = useCallback(() => {
    let filters = [];
    if (filterBy.length && options) {
      const filtersCollection = options.filters;
      filterBy.forEach((appliedFilter) => {
        if (
          filtersCollection[0].options &&
          filtersCollection[0].options.length
        ) {
          filtersCollection[0].options.forEach((filter) => {
            if (appliedFilter === filter.id) {
              filters.push(filter);
            }
          });
        }
      });
    }
    setAppliedFilter(filters);
  }, [filterBy, options]);

  useEffect(() => {
    populateAppliedFilters();
  }, [filterBy, populateAppliedFilters]);

  useEffect(() => {
    if (isQrModeEnabled) {
      return scrollToSearchedResults(0);
    }
    const scrollOffsetMobileView = 240;
    const scrollOffsetDesktopView = 400;
    if ((sortBy && sortBy.length) || filterBy.length || showingSearchResults) {
      if (ResponsiveHelper.isMobile(screenWidth)) {
        scrollToSearchedResults(scrollOffsetMobileView);
      } else {
        scrollToSearchedResults(scrollOffsetDesktopView);
      }
    }
  }, [sortBy, filterBy, screenWidth, showingSearchResults, isQrModeEnabled]);

  useEffect(() => {
    if (categoryWiseItemList.length === 1) {
      if (categoryWiseItemList[0].slug === "search-results") {
        setshowingSearchResults(true);
      }
    } else {
      setshowingSearchResults(false);
    }
  }, [categoryWiseItemList]);

  useEffect(() => {
    const handleScrollTopMarker = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveMainCategory(null);
          setActiveSubCategory(null);
          if (isMountedRef.current) {
            historyPush(menuRoute);
          } else {
            isMountedRef.current = true;
          }
        }
      });
    };

    const scrollTopMarkerObserver = new IntersectionObserver(
      handleScrollTopMarker,
      observerConfig,
    );
    scrollTopMarkerObserver.observe(scrollTopMarkerRef.current);

    return () => scrollTopMarkerObserver.disconnect();
  }, [historyPush, observerConfig, menuRoute]);

  const scrollToSearchedResults = (scrollToOffset) => {
    window.scrollTo(0, scrollToOffset);
  };

  const trackAnalytics = (category) => {
    const item_ids = [];
    category?.items.forEach((item) => item_ids.push(item.id));
    const eventObj = {
      name: category.name,
      item_ids: item_ids,
    };
    analyticsPubSub.publish(ANALYTICS_EVENTS.CATEGORY_DETAIL, eventObj);
  };

  const onCategoryActivation = (category) => {
    if (!isMounted) return;
    if (category.is_sub_category) {
      setActiveMainCategory(category.main_category_id);
      setActiveSubCategory(category.id);
    } else {
      setActiveMainCategory(category.id);
    }
    trackAnalytics(category);
    historyPush(`${menuRoute}/${category.slug}`);
    handleClose();
  };

  const handleScrollToActiveSection = (id) => {
    let announcementBarHeight = 0;
    const announcementBar = document.getElementById("announcement-bar-xyz");
    if (announcementBar) {
      announcementBarHeight = announcementBar.offsetHeight;
    }
    const element = document.getElementById(id);
    if (!element) return;

    //to make sure it scrolls to the top of the category/subCategory section
    let offset = ResponsiveHelper.isMobile(screenWidth) ? 140 : 190;
    offset = offset + announcementBarHeight;

    const bodyRectTop = document.body.getBoundingClientRect().top;
    const elementRectTop = element.getBoundingClientRect().top;
    const elementPosition = elementRectTop - bodyRectTop;
    const offsetPosition = elementPosition - offset;
    window.scrollTo({
      top: offsetPosition,
    });
  };

  useEffect(() => {
    // to unhighlight sub-category once its inactive
    const main = categoryWiseItemList.find(
      (mainCategory) => mainCategory.id === activeMainCategory,
    );
    if (!main?.sub_categories?.length) {
      setActiveSubCategory(null);
    }
  }, [activeMainCategory, categoryWiseItemList]);

  const getCategoryItemCount = (category) => {
    var count = 0;
    // add main category count
    count = count + category.item_count;
    const subCategories = category.sub_categories;
    if (subCategories && subCategories.length) {
      subCategories.forEach((subCat) => {
        // add sub category count
        count += subCat.item_count;
      });
    }
    return count;
  };

  const renderCategoryLinkPlaceholder = (_, index) => {
    return (
      <Shimmer key={index}>
        <div className="category-placeholder" />
      </Shimmer>
    );
  };

  const renderPlaceholderList = () => {
    return (
      <div className="item-list-placeholder">
        <div className="element-placeholder">
          <div className="category-item-list-placeholder">
            {ITEM_DUMMY_DATA.map((_, index) => (
              <ItemCard key={index} />
            ))}
          </div>
        </div>
      </div>
    );
  };

  const handleRemoveFilter = (filter) => {
    if (filter.id) {
      const index = filterBy.findIndex((element, index) => {
        if (element === filter.id) {
          return true;
        }
        return false;
      });
      let filtersByCopy = JSON.parse(JSON.stringify(filterBy));
      filtersByCopy.splice(index, 1);
      handleRemoveFilters(filtersByCopy);
    }
  };

  const handleClearAllFilters = () => {
    handleRemoveFilters([]);
  };

  const scrollCategoryLink = (scrollOffset) => {
    setCategoryScrollEvent({
      scrollWidth: categoryLinksRef.current.scrollWidth,
      clientWidth: categoryLinksRef.current.clientWidth,
      scrollLeft: (categoryLinksRef.current.scrollLeft += scrollOffset),
    });
    categoryLinksRef.current.scrollLeft += scrollOffset;
  };

  const open = Boolean(anchorEl);
  const id = open ? "category-link-list-popover" : undefined;
  const showLeftArrow =
    categoryScrollEvent && categoryScrollEvent.scrollLeft > 0;
  const showRightArrow =
    categoryScrollEvent &&
    categoryScrollEvent.scrollLeft + categoryScrollEvent.clientWidth <
      categoryScrollEvent.scrollWidth;

  const mainCategoryRefs = listData?.reduce((acc, { id }) => {
    acc[id] = createRef();
    return acc;
  }, {});

  return (
    <div className="catalogue-wrapper">
      <div className="scrollTopMarker" ref={scrollTopMarkerRef}>
        {/* for scrollTopMarker Reference */}
      </div>

      <div className="item-list-wrapper" id="item-list-wrapper">
        <div
          className={sticky.isSticky ? "list-wrapper sticky" : "list-wrapper"}
          ref={headerRef}
          style={sticky.isSticky ? { top: headerHeight } : {}}
        >
          {!showingSearchResults && (
            <div
              className="container"
              id="category-link-list"
              style={{ position: "relative" }}
            >
              {!isMobileView && showLeftArrow ? (
                <div
                  className="category-scroll-left-button"
                  onClick={() => scrollCategoryLink(-300)}
                >
                  <ChevronIcon
                    fill={COLOR_NIGHT_RIDER}
                    className="scroll-button-left-icon"
                    size={{ width: 12, heigth: 12 }}
                  />
                </div>
              ) : null}

              <div
                className={`category-list ${
                  sticky.isSticky ? "container" : ""
                }`}
                ref={categoryLinksRef}
                style={{
                  padding:
                    showLeftArrow || showRightArrow
                      ? showLeftArrow
                        ? "10px 0px 10px 30px"
                        : "10px 30px 10px 0px"
                      : "10px 0px 10px 0px",
                }}
              >
                {loading
                  ? ITEM_CATEGORY_DUMMY_DATA.map((category, index) =>
                      renderCategoryLinkPlaceholder(category, index),
                    )
                  : categories && categories.length
                    ? categories.map((category, index) => (
                        <CategoryNavigator
                          key={category.id}
                          appliedFilters={appliedFilters}
                          category={category}
                          primaryColor={primaryColor}
                          headerRect={categoryLinksRef?.current?.getBoundingClientRect()}
                          activeMainCategory={activeMainCategory}
                          activeSubCategory={activeSubCategory}
                          handleScrollToActiveSection={
                            handleScrollToActiveSection
                          }
                          handleAutoScroll={handleAutoScroll}
                        />
                      ))
                    : null}
              </div>

              {!isMobileView && showRightArrow ? (
                <div
                  className="category-scroll-right-button"
                  onClick={() => scrollCategoryLink(300)}
                >
                  <ChevronIcon
                    fill={COLOR_NIGHT_RIDER}
                    className="scroll-button-right-icon"
                    size={{ width: 12, heigth: 12 }}
                  />
                </div>
              ) : null}
            </div>
          )}

          {!!appliedFilters.length && (
            <ul className="applied-filters-wrapper container">
              {appliedFilters.map((filter, index) => (
                <AppliedFilter
                  key={filter.title}
                  filter={filter}
                  index={index}
                  primaryColor={primaryColor}
                  handleRemoveFilter={handleRemoveFilter}
                />
              ))}
              <Typography
                variant="h4"
                weight="regular"
                className="applied-filter-text"
                fontColor={primaryColor}
                style={{ cursor: "pointer" }}
                onClickCallback={handleClearAllFilters}
              >
                {t("common.clearAll")}
              </Typography>
            </ul>
          )}
        </div>

        {categoryWiseItemList?.length ? (
          <div className="mobile-view-category-list-wrapper">
            <FloatingActionButton
              buttonText={
                open ? (
                  <CrossIcon
                    fill={COLOR_WHITE}
                    size={{ width: 12, heigth: 12 }}
                  />
                ) : (
                  t(`sidebar.menu`)
                )
              }
              style={{
                zIndex: 1000,
                backgroundColor: open ? COLOR_NIGHT_RIDER : secondaryColor,
                bottom:
                  cart?.totalItemsCount > 0 || currentOrder ? "126px" : "70px",
                borderRadius: open ? "50%" : "18px",
                padding: open ? "11px 15px" : "10px 16px",
              }}
              clickHandler={handleClick}
            />

            <Menu
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              classes={{
                paper: `mobile-view-link-list ${
                  cart?.totalItemsCount > 0 ? "lift-up" : ""
                }`,
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transitionDuration={0}
            >
              <ul className="mobile-view-category-list">
                {categoryWiseItemList.map((category) => (
                  <MobileCategoryNavigator
                    key={category.id}
                    category={category}
                    activeMainCategory={activeMainCategory}
                    activeSubCategory={activeSubCategory}
                    getCategoryItemCount={getCategoryItemCount}
                    handleScrollToActiveSection={handleScrollToActiveSection}
                    primaryColor={primaryColor}
                    setActiveMainCategory={setActiveMainCategory}
                    setActiveSubCategory={setActiveSubCategory}
                    handleClose={handleClose}
                  />
                ))}
              </ul>
            </Menu>
          </div>
        ) : null}

        <div
          className="item-list"
          style={{ paddingTop: sticky.isSticky ? "70px" : "0px" }}
        >
          {recommendedError && (
            <GenericErrorInline primaryColor={primaryColor} />
          )}

          {loading ? (
            renderPlaceholderList()
          ) : listData && listData.length ? (
            listData.map((category) => (
              <Section
                key={category.id}
                category={category}
                activeSubCategory={activeSubCategory}
                activeMainCategory={activeMainCategory}
                primaryColor={primaryColor}
                primaryTextColor={primaryTextColor}
                secondaryColor={secondaryColor}
                secondaryTextColor={secondaryTextColor}
                selectedFontFamily={selectedFontFamily}
                mainCategoryRefs={mainCategoryRefs}
                observerConfig={observerConfig}
                onCategoryActivation={onCategoryActivation}
                setActiveMainCategory={setActiveMainCategory}
                fetchVisibleCategory={fetchVisibleCategory}
              />
            ))
          ) : (
            <div className="no-items-found">
              <EmptyList className="no-items-found-icon" fill={primaryColor} />
              <Typography
                variant="h1"
                weight="bold"
                className="no-items-found-message"
              >
                {t("common.noItemsFound")}
              </Typography>
            </div>
          )}

          {catalogueError && <GenericErrorInline primaryColor={primaryColor} />}
        </div>
      </div>

      <GenericErrorDialog
        primaryColor={primaryColor}
        open={errorDialog}
        handleClose={handleErrorDialogClose}
        forComponent="menu"
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  categories: state.catalogue?.categories || [],
});

export default withRouter(connect(mapStateToProps)(ItemList));
