import { Component } from "react";

import renderHelmet from "helpers/helmetHelper";
import pageSelector from "external/store/selectors/pageSelector";
import seoHelper from "external/helpers/seoHelper";
import GenericErrorDialog from "common/something-went-wrong/index.component";

import "./index.component.scss";

export class ErrorBoundry extends Component {
  state = {};
  defaultPageData = {
    components: [],
    customizations: {},
    enabled: true,
    name: "Page Not Found",
    seo: {
      en: {
        description: "",
        keywords: "",
        title: "404 - Page Not Found",
      },
    },
    _id: "page_not_found",
  };
  constructor(props) {
    super(props);
    this.initializeErrorBoundry();
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  initializeErrorBoundry() {
    this.setState({ hasError: false, error: null });
    const config = window.ROOT_CONFIG;
    this.pageData = config
      ? pageSelector.getPageNotFoundPage({ config })
      : this.defaultPageData;
    this.seoData = seoHelper(this.pageData.seo);
    this.seoData.id = this.pageData._id;
  }

  componentDidCatch(error, errorInfo) {
    console.log("Error caught in ErrorBoundary:", error);
    console.log("Error info:", errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          {renderHelmet(this.seoData)}
          {this.props.fallback ? (
            this.props.fallback
          ) : (
            <div className="centered-container">
              <GenericErrorDialog />
            </div>
          )}
        </div>
      );
    }

    return this.props.children;
  }
}
